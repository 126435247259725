.mat-row:nth-child(2n+1) {
  background-color:#F0F0F0  !important;
  height: 36px !important;
}

.mat-row:nth-child(2n) {
  background-color: #f0f8ff !important;
}

.mat-row:nth-child(4n) {
  background-color:white !important;
}

.mat-row:nth-child(4n+1) {
  background-color: white !important;
  height: 36px !important;
}

table{
  border-collapse: inherit !important;
}

.filtericon
{
  color: #666666 !important;
}

.mat-sort-header-arrow {
  color: #666666 !important;
}

.mat-header-cell {
  background: linear-gradient(#FFFFFF,#E9E9E9) !important; 
  color:#666666 !important;    
}

.my-form-field.mat-form-field-appearance-legacy .mat-form-field-label,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
	.mat-form-field-label {
    color: #666666 !important;
  }

.my-form-field.mat-form-field-appearance-legacy .mat-input-element {    
  color:#666666 !important;
}

table.mat-table {
  border: #E0E0E0 1px solid !important;
}

tr.mat-header-row{
  height: 40px !important;
}

.app-sidebar.text-lighter .logo-src{
  background-repeat: no-repeat;
}

.btn-primary.btn-shadow{
  box-shadow: none;
}

.mat-elevation-z8 {
  box-shadow: none;
}

.main-card{
  box-shadow: none;
  border:#E0E0E0 1px solid !important
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded), .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: 0;
  margin-bottom: 10px; 
}

.mat-expansion-panel-header{
  background: linear-gradient(#FFFFFF,#E9E9E9) !important; 
  border:#E0E0E0 1px solid !important;
}

.app-sidebar.text-lighter .app-header__logo {
  background: linear-gradient(#FFFFFF,#E0E0E0) !important;
}

.app-header .app-header__content {  
  background: linear-gradient(#FFFFFF,#E0E0E0) !important;
}

label {  
  display: inline-block !important;
  margin-bottom: 4px !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #E0E0E0  !important;
  opacity: 1  !important;
  border: none  !important;
}

@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: #006CA3;    
    border-color: $input-focus-border-color;
    outline: 0;   
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

.form-control {
  border: #E0E0E0 1px solid;
}

.mat-sort-header-arrow{
  margin: 0 0 0 -20px !important;
}

.mat-table-sticky{
  right: 1px !important;
}

.app-sidebar .app-header__logo{
  padding: 0 1.0rem;
}

.btn-primary {
  color: #006CA3;
  background: #fff;
  border: #E0E0E0 1px solid;
}

.app-page-title .page-title-heading { 
  color: #666666;
}

.app-header .app-header__content .app-header-left {
  display: flex;
  align-items: center;
  padding: 0;
}

.app-header .app-header__content {
  background: linear-gradient(#FFFFFF, #E0E0E0) !important;
  border-bottom: 1px solid #e0e0e0;
}

.app-main__inner{
  background: #ffffff !important;
}

.app-sidebar{
  box-shadow:none;
}

.app-header.header-shadow {
  box-shadow: none;
}

.bg-night-sky {
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #132e7d 100%) !important;
}

.app-sidebar.text-lighter .hamburger-inner, .app-sidebar.text-lighter .hamburger-inner::before, .app-sidebar.text-lighter .hamburger-inner::after {
  background: #132e7d !important;
}

.app-sidebar.text-lighter .hamburger-box:hover .hamburger-inner, .app-sidebar.text-lighter .hamburger-box:hover .hamburger-inner::before, .app-sidebar.text-lighter .hamburger-box:hover  .hamburger-inner::after {
  background: #132e7d !important;
}

.closed-sidebar .app-sidebar .logo-src {
  width: 50px;
  margin: 0 auto;
  background: url(/assets/images/sc-ic.png) !important;
  background-repeat: no-repeat;
}

.mat-expansion-panel:not([class*='mat-elevation-z']){
  margin-bottom: 10px !important;
}

.form-group > label {
  color: #313131;
  margin-bottom:4px !important;
  font-size: 10px !important;
  font-family: 'Work Sans', sans-serif;
}

  .app-sidebar.text-lighter .v-sidebar-menu .vsm-link:hover, .app-sidebar.text-lighter .v-sidebar-menu .vsm-link.active-item, .app-sidebar.text-lighter .v-sidebar-menu .vsm-item.active-item > .vsm-link{
    background: #2e9ed5 !important;
}

.v-sidebar-menu .vsm-title { 
  font-size: 16px;
}

.app-theme-white .app-sidebar .app-sidebar-content {
  border: #E0E0E0 1px solid !important;
}

//use in CPOE Module
.mat-expansion-panel:not([class*='mat-elevation-z']) {
  border: #E0E0E0 1px solid;
  box-shadow: none;
}

.mat-tab-label-container {
  background: linear-gradient(#FFFFFF, #E9E9E9) !important;
}

.closed-sidebar-open.closed-sidebar .app-sidebar .logo-src {
  width: 138px !important;
  height: 32px !important; 
}

.closed-sidebar .app-sidebar .logo-src {
  width: 50px !important;
  height: 50px !important; 
}

.logo-src {
  width: 138px !important;  
  height: 32px !important;
}

.mat-expansion-panel-body {
   padding: 0px !important; 
}

.mat-radio-outer-circle {
  top:5px !important;
}

.mat-radio-checked .mat-radio-inner-circle {
  top: 5px;
}

.mat-select-panel{
  min-width: calc(100% + 45px) !important;
}

.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mat-error {
  color: red !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color:red !important;
}

.card-body{
  padding: 1rem;
}

.btn-group, .btn-group-vertical { 
  display: block;
}

.swal2-checkbox {
  display: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 0.5em 0;
}

.mat-form-field-appearance-outline .mat-form-field-flex{
  margin-top: -0.5em !important;
}

.cdk-overlay-container{ z-index: 1100 !important; }

.h-60{
  height: 60vh !important;
}
.datePickerField{
  margin-bottom: -2.25em;
}

.slick-slider .slide-img-bg {
  background: linear-gradient(#033C73, #2e9ed5 ,) !important;
}
.v-sidebar-menu .vsm-title {
  padding-left: 5px !important;
}
.app-sidebar .app-sidebar-content .vsm-title-in {
  padding-left: 5px !important;
}

.v-sidebar-menu .vsm-dropdown .vsm-list {
  padding-left: 0 !important;
}

.scroll-area-xs{
  height:100px !important;
}

.is-invalid{
  background-image: none !important;
}

.icon-wrapper.freetext {
  width: 40px !important;
  height: 40px !important;
}

.app-footer .app-footer__inner{
  height: 40px !important;
  padding: 10px 1.5rem 0px 0.75rem;
}

button:focus{  
  background: #2e9ed5  !important;  
  color: #e7e4e4;
}

//-------------
.mat-select-value {
  display: table-cell;
  max-width: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #C71C22;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #C71C22;
}
.mat-form-field-appearance-fill .mat-form-field-ripple {
  bottom: 0;
  height: 1px;
}

field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #C71C22;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #313131;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent, .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #C71C22;
}
.mat-form-field-required-marker {
  color: #C71C22;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #C71C22;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #C71C22;
}
.mat-form-field{
  width: 100%;
}

.MatSelectNew .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42) !important;
}

.multiselect-dropdown .dropdown-btn .selected-item{
  max-width: 100%;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {background: rgba(0,0,0,.4) !important;;-moz-box-shadow: rgba(0,0,0,.4) !important;;-webkit-box-shadow: rgba(43, 35, 35, 0.4) !important;;}
.swal-blur{backdrop-filter: blur(6px);.swal2-popup .swal2-styled.swal2-confirm,.swal2-popup .swal2-styled.swal2-cancel{ width: 6em !important; height: 2.5em !important;outline: none !important;}
.swal2-input{height: 2.625em;padding: 0 .75em;background-color: #e8f0fe !important;outline: rgba(0, 0, 0, 0.42);}}

.swal-popup{background: rgba(255, 255, 255, 0) !important;}