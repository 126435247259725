// Buttons

// 2x Outline Border

.btn-outline-2x {
  border-width: 2px;

  &.btn-group {
    .btn {
      border-width: 2px;

      & + .btn {
        margin-left: -2px;
      }
    }
  }

  &.btn-group-vertical {
    .btn {
      border-width: 2px;

      & + .btn {
        margin-top: -2px;
      }
    }
  }
}

.btn-pill {

  .ladda-progress {
    @include border-left-radius(50px);
    @include border-right-radius(50px);
  }

  &.btn-group {
    & > .btn:first-child {
      @include border-left-radius(50px);
    }
    & > .btn:last-child {
      @include border-right-radius(50px);
    }
  }

  &.btn-group-vertical {
    & > .btn:first-child {
      @include border-top-radius(12px);
    }
    & > .btn:last-child {
      @include border-bottom-radius(12px);
    }
  }
}

.btn-square {
  @include border-radius(0);

  .ladda-progress {
    @include border-radius(0);
  }

  &.btn-group-vertical,
  &.btn-group {
    & > .btn {
      @include border-radius(0);
    }
  }
}

.btn-group {
  .btn {
    font-size: 0.8rem;
    font-weight: 500;
  }
}

.btn {
  font-size: 0.8rem;
  font-weight: 500;

  // Pills Buttons

  &.btn-pill.btn-wide,
  &.btn-pill {
    @include border-left-radius(50px);
    @include border-right-radius(50px);
  }

}

// Dashed Border

.btn-dashed {
  border-style: dashed;

  &.btn-group {
    border-style: none;

    .btn {
      border-style: dashed;
    }
  }

  &.btn-group-vertical {
    border-style: none;

    .btn {
      border-style: dashed;
    }
  }
}

// Icon Buttons

.btn-icon {
  vertical-align: bottom;

  &.btn-icon-right {
    .btn-icon-wrapper {
      margin-left: ($spacer / 2);
      margin-right: 0;
    }
  }

  .btn-icon-wrapper {
    margin-right: ($spacer / 2);
    margin-left: 0;
    margin-top: 0;
    font-size: 17px;
    vertical-align: middle;
    transition: none;
    display: inline-block;
  }

  &.btn-link {
    text-decoration: none;
  }

  &.btn-lg:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 25px;
    }
  }

  &.btn-sm:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 16px;
    }
  }
}

.btn-icon-only {
  .btn-icon-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  &.dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

// Hover Shine {

.btn-hover-shine {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }

  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.4s ease-in-out;
  }

  &.btn-pill {
    &::after {
      @include border-left-radius(50px);
      @include border-right-radius(50px);
    }
  }
}

// Vertical Icons {

.btn-icon-vertical {
  padding-top: ($btn-block-spacing-y * 2);
  padding-bottom: ($btn-block-spacing-y * 2);
  min-width: 100px;

  .btn-icon-wrapper {
    display: block;
    font-size: 200%;
    margin: 5px 0;
    opacity: .6;
  }

  &.btn-link {
    text-decoration: none;
  }

  &.btn-lg:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 25px;
    }
  }

  &.btn-sm:not(.btn-block) {
    .btn-icon-wrapper {
      font-size: 16px;
    }
  }

  &:active,
  &.active,
  &:hover {
    .btn-icon-wrapper {
      opacity: 1;
    }
  }

  &.btn-icon-bottom {
    .btn-icon-wrapper {
      margin: ($spacer / 5) 0 5px;
    }
  }

  &.btn-transition-text {
    .btn-icon-wrapper {
      transition: all .2s !important;
    }

    &:hover {
      .btn-icon-wrapper {
        transform: scale(1.3);
      }
    }

    &.btn-transition-alt {

      &:hover {
        .btn-icon-wrapper {
          color: $white !important;
        }
      }
    }
  }
}

.btn-icon-lg {
  font-size: $h1-font-size !important;
}

// Buttons Transitions

.btn-transition {
  color: $gray-600;
  border-color: $gray-200;
  background-color: none;

  &.btn-outline-link {
    border-color: transparent;
    background-color: transparent;

    &:hover {
      color: $primary;
      background: $gray-100;
    }
  }

  &.disabled,
  &:disabled {
    color: $gray-600;
    border-color: $gray-200;
  }

  &:hover {
    .btn-icon-wrapper {
      transition: none;
    }
  }
}

.btn-transition-alt {
  &:hover {
    .icon-gradient {
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
      background-clip: initial;
      text-fill-color: initial;
      background: none !important;
      color: $white;
    }
  }
}

// Square Buttons

.btn-square {
  @include border-radius(0 !important);
}

// Wide buttons

.btn {
  &.btn-wide {
    @include button-size($btn-padding-y, ($btn-padding-x * 2), $font-size-xs, $btn-line-height, $btn-border-radius);
  }
}

.btn-lg {
  &.btn-wide {
    @include button-size($btn-padding-y-lg, ($btn-padding-x-lg * 2), $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
  }
}

.btn-sm {
  &.btn-wide {
    @include button-size($btn-padding-y-sm, ($btn-padding-x-sm * 2), $font-size-xs, $btn-line-height-sm, $btn-border-radius-sm);
  }
}

// Dropdown toggle

.dropdown-toggle {
  &::after {
    position: relative;
    top: 2px;
    opacity: .8;
  }
}

.dropright {
  .dropdown-toggle {
    &::after {
      top: 0;
    }
  }
}

.dropdown-toggle-split {
  border-left: rgba(255, 255, 255, .1) solid 1px;
}

.btn.btn-shadow {
  &.active {
    box-shadow: 0 0 0 0 transparent !important;
  }
}

// Links

$link-color: theme-color("primary");
$link-hover-color: darken($link-color, 15%);

// Generate Buttons

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);

    &.btn-shadow {
      box-shadow: 0 0.125rem 0.625rem rgba($value, .4), 0 0.0625rem 0.125rem rgba($value, .5);

      &:hover {
        box-shadow: 0 0.125rem 0.625rem rgba($value, .5), 0 0.0625rem 0.125rem rgba($value, .6);
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-shadow-#{$color} {
    &:hover {
      box-shadow: 0 0.125rem 0.625rem rgba($value, .4), 0 0.0625rem 0.125rem rgba($value, .5);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);

    &.btn-shadow {
      box-shadow: 0 0.125rem 0.625rem rgba($value, .4), 0 0.0625rem 0.125rem rgba($value, .5);

      &.active:hover,
      &.disabled:hover,
      &:active:hover,
      &:disabled:hover {
        box-shadow: 0 0.125rem 0.625rem rgba($value, .5), 0 0.0625rem 0.125rem rgba($value, .6);
      }

      &:hover {
        box-shadow: (0px 5px 15px 2px rgba($value, 0.19));
      }
    }
  }
}

.btn {
  position: relative;
  @include transition($btn-transition);
}

.btn-light {
  border-color: darken(#f5f5f5, 10%);
}

.btn-outline-light {
  color: darken(#f5f5f5, 40%);
}
