$deep: '::ng-deep';

.showerror{
  display: block !important;
}
.mat-expansion-panel-header{
    background-color:#f0f8ff;    
    height: 40px !important;
    // margin-bottom: 10px !important;
}
.mat-expansion-panel {
    background-color:#f0f8ff;    
}

.mat-expansion-panel-spacing {
    margin: 1px 0 0 0 !important;
}

.mat-action-row {
	padding: 2px 2px 2px 2px !important;
}
 
.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover {
  background: #f0f8ff !important;
}
 


/** Overrides underline color **/
.my-form-field.mat-form-field-appearance-legacy .mat-form-field-underline,
.my-form-field.mat-form-field-appearance-legacy .mat-form-field-ripple,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
.mat-form-field-underline,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
.mat-form-field-ripple {
	background-color: transparent !important;
}

.my-form-field.mat-form-field-appearance-legacy.mat-focused
.mat-form-field-ripple {
	background-color: #666666 !important;
}

/** Overrides label color **/
.my-form-field.mat-form-field-appearance-legacy .mat-form-field-label,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
	.mat-form-field-label {
	color: white;
}

/** Overrides caret & text color **/
.my-form-field.mat-form-field-appearance-legacy .mat-input-element {
	caret-color:#666666;
  color: white;
}
.mat-paginator-container { min-height: 40px!important; }
