@import '../../variables';

$cal-day-view-vars: () !default;
$cal-day-view-vars: map-merge($cal-vars, $cal-day-view-vars);

@mixin cal-day-view-theme($overrides) {
  $theme: map-merge($cal-day-view-vars, $overrides);

  .cal-day-view {
    background-color: map-get($theme, bg-primary);

    .cal-hour-rows {
      border-color: map-get($theme, border-color);
    }

    .cal-hour:nth-child(odd) {
      background-color: map-get($theme, bg-secondary);
    }

    .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-hour:last-child :not(:last-child) .cal-hour-segment {
      border-bottom-color: map-get($theme, border-color);
    }

    .cal-hour-segment:hover,
    .cal-drag-over .cal-hour-segment {
      background-color: map-get($theme, bg-active);
    }

    .cal-event {
      background-color: map-get($theme, event-color-secondary);
      border-color: map-get($theme, event-color-primary);
      color: map-get($theme, event-color-primary);
    }
  }
}

.cal-day-view {
  .cal-hour-rows {
    width: 100%;
    border: solid 1px;
    overflow-x: auto;
    position: relative;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  mwl-calendar-day-view-hour-segment, /* fix for https://github.com/mattlewis92/angular-calendar/issues/260*/
  .cal-hour-segment {
    display: block;
  }

  .cal-hour-segment::after {
    content: '\00a0';
  }

  .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed;
  }

  .cal-time {
    font-weight: bold;
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cal-hour-segment.cal-after-hour-start {
    .cal-time {
      display: none;
    }
  }

  .cal-drag-active .cal-hour-segment {
    pointer-events: none;
  }

  .cal-event-container {
    position: absolute;
    cursor: pointer;

    &.resize-active {
      z-index: 1;
      pointer-events: none;
    }
  }

  .cal-event {
    padding: 5px;
    font-size: 12px;
    border: 1px solid;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
  }

  .cal-all-day-events > * {
    cursor: pointer;
  }

  .cal-draggable {
    cursor: move;
  }

  .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .cal-drag-active {
    z-index: 1;

    & * {
      pointer-events: none;
    }
  }

  .cal-resize-handle {
    width: 100%;
    height: 4px;
    cursor: row-resize;
    position: absolute;

    &.cal-resize-handle-after-end {
      bottom: 0;
    }
  }
}

@include cal-day-view-theme($cal-day-view-vars);
